import { render, staticRenderFns } from "./ChangePrice.vue?vue&type=template&id=31b07f91&scoped=true"
import script from "./ChangePrice.vue?vue&type=script&lang=js"
export * from "./ChangePrice.vue?vue&type=script&lang=js"
import style0 from "./ChangePrice.vue?vue&type=style&index=0&id=31b07f91&prod&lang=scss&scoped=true"
import style1 from "./ChangePrice.vue?vue&type=style&index=1&id=31b07f91&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b07f91",
  null
  
)

export default component.exports